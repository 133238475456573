const recipes = [
    {
        name: "Frittata or Omelets and Toast 🍳🍞🌿",
        recipeId: "frittata-or-omelets-and-toast",
        ingredients: [
            "3 large eggs or egg substitute",
            "1/4 cup diced vegetables (spinach, tomatoes, onions, mushrooms, etc.)",
            "1 tsp Earth Balance (or vegan butter)",
            "Salt and pepper to taste",
            "1 slice whole-grain toast",
        ],
        instructions: [
            "Whisk eggs with salt and pepper.",
            "Heat a nonstick skillet over medium heat, melt the butter, and sauté the vegetables.",
            "Pour in the eggs and cook until set. Fold or serve flat.",
            "Toast the bread and serve alongside.",
        ],
    },
    {
        name: "Breakfast Cookie with Fruit Casserole on the Side 🍪🍓🍌",
        recipeId: "breakfast-cookie-with-fruit-casserole",
        story: "This breakfast cookie recipe was inspired by several ideas I found while searching for the perfect breakfast cookie. The fruit casserole is the perfect side and has been a family favorite for over 20 years.",
        ingredients: [
            "1 cup creamy peanut butter (or other nut butter)",
            "1/4 cup honey or maple syrup",
            "1/3 cup apple butter",
            "1 teaspoon vanilla extract",
            "2 medium ripe bananas, mashed",
            "1/2 teaspoon salt",
            "1 teaspoon ground cinnamon",
            "2 1/4 cups quick oats or old-fashioned rolled oats",
            "1/2 cup dried cranberries or raisins",
            "2/3 cup chopped nuts (e.g., almonds, walnuts, or pistachios)",
            "1/4 cup ground flaxseed (optional)",
            "1 cup strawberries, chopped (for fruit casserole)",
            "1 medium banana, sliced (for fruit casserole)",
            "1/2 cup strawberry banana yogurt (for fruit casserole)"
        ],
        instructions: [
            "Preheat the oven to 325°F. Line a baking sheet with parchment paper or a silicone baking mat.",
            "In a large mixing bowl (or stand mixer with a paddle attachment), combine the peanut butter, honey, apple butter, vanilla extract, mashed bananas, salt, and cinnamon until smooth.",
            "Gradually stir in the oats, dried cranberries (or raisins), chopped nuts, and optional flaxseed until fully combined.",
            "Scoop about 1/4-cup mounds of the dough onto the prepared baking sheet, flattening each cookie slightly (cookies will not spread).",
            "Bake for 14 to 16 minutes until golden brown but still soft. Allow the cookies to cool on the baking sheet for 5 minutes, then transfer to a wire rack to cool completely.",
            "For the fruit casserole, combine the chopped strawberries, sliced banana, and strawberry banana yogurt in a bowl. Mix gently to coat the fruit evenly.",
            "Serve the fruit casserole alongside the breakfast cookies for a balanced and satisfying meal."
        ],
        notes: [
            "For a vegan option, replace honey with maple syrup and use dairy-free yogurt for the fruit casserole.",
            "The apple butter adds a touch of sweetness and moisture—it's a wonderful addition inspired by Sally’s Baking Addiction!",
            "Customize the cookies with your favorite dried fruits, seeds, or nuts.",
            "Prepare the fruit casserole up to a day in advance and store it in the refrigerator."
        ]
    },
    {
        name: "British Fry Up 🇬🇧🍅🍳",
        recipeId: "british-fry-up",
        ingredients: [
            "1 cup Great Northern beans",
            "2 eggs (poached, fried, or scrambled)",
            "1 medium tomato, grilled",
            "1/2 cup mushrooms, sautéed",
            "2 slices whole-grain toast",
        ],
        instructions: [
            "Warm the beans, grill the tomato, and sauté the mushrooms.",
            "Cook eggs to your preference.",
            "Serve with toast and a side of Earl Grey tea.",
        ],
    },
    {
        name: "Buckwheat Pancakes with Blueberries and Almonds 🫐🥞🌰",
        recipeId: "buckwheat-pancakes-with-blueberries-and-almonds",
        ingredients: [
            "1 1/2 cups buckwheat flour",
            "1 tbsp baking powder",
            "1 mashed banana",
            "2 cups pea milk (Ripple®, Sproud®, or another brand)",
            "1 tbsp maple syrup",
            "1/2 cup sliced almonds",
            "1/2 cup frozen or fresh blueberries",
        ],
        instructions: [
            "In a large bowl, mix the buckwheat flour and baking powder.",
            "In a separate bowl, mash the banana and mix it with the pea milk and maple syrup.",
            "Gradually combine the wet ingredients with the dry ingredients, stirring until just mixed. Do not overmix.",
            "Heat a non-stick skillet or griddle over medium heat and lightly grease if needed.",
            "Pour 1/4 cup of batter per pancake onto the skillet. Sprinkle a few blueberries and sliced almonds on each pancake while cooking.",
            "Cook until bubbles form on the surface and edges look set, about 2-3 minutes. Flip and cook the other side until golden brown, about 1-2 minutes more.",
            "Serve pancakes warm with additional maple syrup, blueberries, and almonds on top if desired.",
        ],
        notes: [
            "Pea milk brands like Ripple® and Sproud® work well in this recipe for a creamy texture.",
            "Frozen blueberries are convenient and work just as well as fresh. No need to thaw them before adding to the batter.",
            "For a nut-free option, omit the almonds or substitute with seeds like pumpkin or sunflower seeds.",
        ],
        brands: [
            "Ripple® Pea Milk",
            "Sproud® Pea Milk",
            "Silk® Protein Milk (pea-based option)",
        ],
        toppings: [
            "Extra blueberries",
            "Sliced almonds",
            "Additional maple syrup",
        ],
    },
    {
        name: "Pesto Bennies 🌱🍅🍳",
        recipeId: "pesto-bennies",
        ingredients: [
            "1 whole-grain English muffin (e.g., Ezekiel English Muffins, Sprouted Grain®, or Dave's Killer Bread Rockin' Grains Organic English Muffins®)",
            "2 tbsp homemade pesto (recipe below) or high-quality store-bought pesto",
            "1 medium tomato, sliced",
            "2 poached eggs",
            "Optional: 1 tbsp grated Romano or Parmesan-Reggiano cheese"
        ],
        instructions: [
            "Toast the English muffin halves until golden brown.",
            "Spread 1 tablespoon of pesto (homemade or store-bought) on each toasted muffin half.",
            "Layer with tomato slices.",
            "Lightly spray silicone egg poaching cups with oil. Fill a pot with 2-3 inches of water and bring to a gentle boil. Place the silicone cups in the water, crack an egg into each cup, cover the pot, and let cook for 4-5 minutes for a runny yolk or longer for a firmer yolk.",
            "Remove poached eggs from the cups and place one on each muffin half.",
            "Optionally, sprinkle grated cheese on top before serving."
        ],
        notes: [
            "For a low-sodium option, use NuSalt® to taste instead of regular salt in the pesto.",
            "If you prefer store-bought pesto, choose a high-quality brand like Rana® or Barilla®. Adjust seasoning as needed.",
            "Poaching eggs in silicone cups ensures consistent results. These cups are reusable and easy to clean.",
            "This dish is perfect for brunch or a hearty breakfast."
        ],
        brands: [
            "NuSalt® (low-sodium salt alternative)",
            "Ezekiel English Muffins, Sprouted Grain®",
            "Dave's Killer Bread Rockin' Grains Organic English Muffins®"
        ],
        products: [
            "Silicone Egg Poachers",
            "Extra Virgin Olive Oil"
        ],
        "subRecipes": [
            {
                name: "Homemade Pesto",
                ingredients: [
                    "2 cups fresh basil leaves, packed",
                    "1 cup spinach",
                    "3/4 cup freshly grated Romano or Parmesan-Reggiano cheese (optional)",
                    "3/4 cup extra virgin olive oil",
                    "1/4 cup pine nuts (or substitute chopped walnuts)",
                    "1/4 cup pistachios",
                    "3 garlic cloves, minced",
                    "1 small shallot",
                    "1/2 tsp salt (use NuSalt® for a lower sodium option)",
                    "1/4 tsp freshly ground black pepper, more to taste"
                ],
                instructions: [
                    "Combine basil, spinach, cheese (if using), pine nuts, pistachios, garlic, shallot, salt, and pepper in a food processor.",
                    "Pulse until finely chopped.",
                    "With the processor running, slowly drizzle in olive oil until the pesto reaches your desired consistency.",
                    "Taste and adjust seasoning as needed. Store in an airtight container in the refrigerator for up to a week."
                ]
            }
        ]
    },
    {
        name: "Suzie’s Sunrise Wakeup Shake 🌅🥤🍊",
        recipeId: "suzies-sunrise-wakeup-shake",
        story: "The shake is a very forgiving recipe. Want to use plain yogurt? Great, just add a little sweetener of your choice and maybe a splash of vanilla. Don’t have frozen orange juice? Throw in some frozen strawberries. Out of wheat germ? Use some hemp seeds. Why pea protein powder instead of another protein powder? I think it tastes better; use what you prefer. It was inspired by a recipe I read in Seventeen magazine, sometime around 1984.",
        ingredients: [
            "2/3 cup vanilla yogurt",
            "1 ripe banana (frozen, if possible)",
            "1 tbsp flax seeds",
            "2 tbsp wheat germ",
            "1/4 cup orange juice concentrate",
            "2/3 cup pea milk",
            "dry meal replacement vanilla mix (like ½ serving of Ka’Chava OR 1 full serving of Vega One All-In-One Shake OR 1 full serving of Garden of Life Raw Organic Meal OR 1/3 serving of Huel Powder. May also use pea protein powder or reduced-fat peanut butter powder. Calories, etc., and taste will vary.)"
        ],
        instructions: [
            "Blend all ingredients with ice.",
            "Adjust sweetness with stevia or sweetener.",
        ],
        notes: [
            "Customize with different protein powders or sweeteners based on your preference.",
            "Feel free to substitute or add ingredients, such as frozen strawberries or hemp seeds, if needed."
        ]
    },
    {
        name: "Oatmeal with Fruit and Nuts 🥣🍌🌰",
        recipeId: "oatmeal-with-fruit-and-nuts",
        ingredients: [
            "1 cup rolled oats",
            "2 cup pea milk",
            "1 cup banana",
            "1/2 cup walnuts",
            "1 tbsp vegan butter",
            "2 tbsp brown sugar or maple syrup",
            "1 tsp vanilla",
        ],
        instructions: [
            "Combine oats and milk. Cook on in rice cooker.",
            "Stir in sweetener, top with fruit and nuts, and serve.",
        ],
    },
    {
        name: "Hippie Hash with Cranberry Juice 🥔🌈🍳",
        recipeId: "hippie-hash-with-cranberry-juice",
        ingredients: [
            "1 cup hash browns",
            "1/2 bell pepper, sliced",
            "1/2 onion, diced",
            "4 broccoli florets, chopped",
            "2 eggs, over-easy",
            "2 tbsp feta cheese",
            "2 slices whole-grain toast",
        ],
        instructions: [
            "Cook hash browns until crispy.",
            "Sauté vegetables in a separate pan.",
            "Top hash browns with vegetables, eggs, and cheese.",
            "Serve with toast and cranberry juice.",
        ],
    },
    {
        name: "Breakfast Crepes 🌯🍓🍫",
        recipeId: "breakfast-crepes",
        ingredients: [
            "2 cups plant milk or milk (I use pea milk)",
            "4 eggs",
            "3 tablespoons vegan butter or butter, melted",
            "1 tablespoon sugar or sweetener of your choice (skip if using a savory filling)",
            "1 teaspoon vanilla (skip if using a savory filling)",
            "1/2 teaspoon salt",
            "1 1/2 cups whole wheat flour"
        ],
        instructions: [
            "Use an electric hand or stand mixer to blend all ingredients until well mixed (about 1-2 minutes).",
            "Spray non-stick cooking spray onto an 8-inch or larger frying pan. Pour about 1/2 cup of batter into the pan.",
            "Turn the pan immediately from side to side to form an even circle.",
            "Cook over medium-low heat for about 2 minutes on one side, then flip and cook for 1 minute on the other side, or until lightly browned.",
            "Remove from the pan and stack until ready to serve.",
            "Makes about 8 crepes.",
            "Serve with your favorite toppings. Fresh fruit, jams, pudding, and nuts are some popular options."
        ],
            notes: [
            "Use fresh toppings for the best flavor. Seasonal fruits work great!",
                "For extra variety, experiment with different fillings and toppings to suit your taste preferences, such as:",
                "Stuffed with tomato, onion, green peppers, spinach, mushrooms, and Swiss cheese (savory option)",
                "Stuffed and topped with fresh strawberries",
                "Stuffed and topped with fresh cinnamon apples",
                "Dark chocolate crepe stuffed with strawberry mascarpone, topped with fresh strawberries and chocolate drizzle",
                "Stuffed with pear and hazelnut"
        ]
    },
    {
        name: "Breakfast Sandwich with a side of Banana 🥯🍳🍌",
        "recipeId": "breakfast-sandwich",
        "ingredients": [
            "1 whole-grain bagel",
            "1 pasture-raised egg, cooked over-easy",
            "1/2 slice cheddar cheese (optional, use sparingly)",
            "1 vegetarian or vegan sausage patty of choice",
            "1 banana or other fresh fruit of choice (for serving)",
            "1-2 slices red onion (optional, for extra flavor)",
            "1 handful fresh spinach or arugula (optional, for a plant boost)",
            "1-2 slices tomato (optional, for freshness)",
            "1/4 avocado, sliced (optional, for creaminess)",
            "Cooking spray or a small amount of oil (for sausage patty, if needed)",
            "1 tsp vegan butter (for cooking egg, if desired)"
        ],
        "instructions": [
            "Lightly grease a pan with cooking spray or a small amount of oil, if needed, and cook the vegetarian or vegan sausage patty according to package instructions.",
            "Melt 1 tsp of vegan butter in a non-stick pan or lightly greased skillet, then cook the egg over-easy.",
            "Toast the whole-grain bagel until golden brown.",
            "Assemble the sandwich: Place the sausage patty, cooked egg, cheese (if using), and any optional toppings on the toasted bagel.",
            "Serve with a banana or other fresh fruit on the side."
        ],
        "notes": [
            "Use cheese sparingly to keep the sandwich heart healthy.",
            "For added texture and nutrients, consider adding red onion, spinach, arugula, tomato, or avocado.",
            "A whole-grain bagel provides fiber and a hearty base for the sandwich.",
            "Serving with a banana or other fresh fruit adds natural sweetness and a boost of nutrients.",
            "A light spray of oil helps prevent sticking when cooking the sausage, while vegan butter can add richness to the egg."
        ]
    },
    {
        name: "Yogurt Parfaits 🥣🍓🥄",
        recipeId: "yogurt-parfaits",
        ingredients: [
            "1/2 cup flavored Greek yogurt (e.g., vanilla or berry flavor)",
            "1/2 cup fruit-flavored regular yogurt (e.g., strawberry or peach)",
            "1/2 cup fresh or frozen berries (e.g., blueberries, raspberries, strawberries)",
            "1/3 cup high-quality granola (e.g., Kind®, Purely Elizabeth®, or Cascadian Farm®)",
            "2 tbsp sliced almonds (or substitute walnuts, pecans, or pistachios)"
        ],
        instructions: [
            "In a glass or bowl, layer the Greek yogurt as the bottom layer.",
            "Add the fruit-flavored regular yogurt as the second layer, spreading it evenly.",
            "Top with fresh or frozen berries of your choice.",
            "Sprinkle granola evenly over the berries.",
            "Finish with sliced almonds or your preferred nuts.",
            "Serve immediately or refrigerate for up to 4 hours for a chilled parfait."
        ],
        notes: [
            "For a lower-calorie option, choose low-fat or non-fat yogurt and a lighter granola.",
            "Frozen berries can be a cost-effective and convenient alternative to fresh berries.",
            "If you prefer a sweeter parfait, drizzle with a small amount of honey or agave syrup."
        ],
        brands: [
            "Yogurt: Chobani®, FAGE®, or Siggi’s®",
            "Granola: Kind®, Purely Elizabeth®, Cascadian Farm®"
        ]
    },
        {
        "name": "Huevos Rancheros 🌮🍳🥑",
        "recipeId": "huevos-rancheros",
        "story": "A healthier take on the classic Mexican breakfast dish, balancing protein, whole grains, and vegetables while reducing excess oil.",
        "prep": [
            "Preheat oven to 375°F (190°C).",
            "Chop onion and bell pepper.",
            "If using tofu scramble, prepare tofu mixture first (see sub-recipe)."
        ],
        "ingredients": [
            "2 corn tortillas",
            "1/2 tbsp extra virgin olive oil (or avocado oil)",
            "1/2 tsp vegan butter",
            "1/4 tsp taco seasoning (or to taste)",
            "1/2 cup (120g or 4 ounces) vegetarian refried beans (optional: mix with green chilis)",
            "1/4 cup or more (40g) diced onion",
            "1/4 cup or more (40g) diced bell pepper",
            "2 eggs (over-easy or to preference, or sub tofu scramble)",
            "1/8 cup (15g) Cotija or Monterey Jack cheese (optional)",
            "1/2 avocado, sliced (optional)",
            "1/4 cup or more (60g) salsa, picante, or fresh diced tomatoes",
            "Lime wedges for squeezing"
        ],
        "instructions": [
            "Preheat oven with cookie sheet inside to 375°F (190°C). Brush both sides of tortillas with a bit of oil. Bake for 6–8 minutes until slightly crispy.",
            "In a skillet, heat remaining oil over medium. Lightly sauté the baked tortillas for ~30 seconds per side until golden and crispy. Sprinkle with taco seasoning. Set aside.",
            "Sauté onions and bell peppers in the same pan for 3–4 minutes until softened.",
            "Warm refried beans with taco seasoning in a small pan. Mix in green chilis if using.",
            "Spread a layer of beans on each tortilla.",
            "Cook the eggs to your preference, or prepare tofu scramble (see sub-recipe).",
            "Top each tortilla with egg or tofu, sautéed peppers and onions, optional cheese, avocado, and salsa or tomatoes.",
            "Finish with a squeeze of lime. Serve immediately."
        ],
        "subRecipes": [
            {
                "name": "Tofu Scramble (for plant-based version)",
                "prep": [
                    "Drain tofu and pat dry with a towel.",
                    "Optional: crumble tofu ahead of time and mix with seasonings."
                ],
                "ingredients": [
                    "6 oz (170g) firm or extra-firm tofu, crumbled",
                    "1/2 tsp turmeric",
                    "1/4 tsp garlic powder",
                    "1/4 tsp onion powder",
                    "1/4 tsp black salt (kala namak) or sea salt",
                    "1 tsp olive oil or vegan butter",
                    "1 tbsp unsweetened pea milk (optional, for creaminess)",
                    "Fresh ground pepper, to taste"
                ],
                "instructions": [
                    "Heat oil or vegan butter in a skillet over medium heat.",
                    "Add crumbled tofu and stir to coat.",
                    "Sprinkle in turmeric, garlic powder, onion powder, and salt.",
                    "Cook 5–7 minutes, stirring occasionally, until heated through and lightly browned.",
                    "Optional: stir in pea milk toward the end for a creamier texture.",
                    "Taste and adjust seasoning if needed. Serve warm as a substitute for eggs."
                ]
            }
        ],
        "shortcuts": [
            "Use store-bought tofu scramble from brands like Hodo® or Just Egg® for convenience.",
            "Use jarred salsa or pre-chopped veggies to save time."
        ],
        "plantBased": [
            "Replace eggs with tofu scramble.",
            "Use avocado and omit cheese, or substitute with vegan cheese or nutritional yeast."
        ],
        "notes": [
            "This recipe is for one adult. Increase amounts for more people.",
            "Tofu scramble can be made in advance and reheated.",
            "Kala namak gives it an eggy flavor, but regular salt works fine too.",
            "Great way to use up leftover tofu!"
        ],
        "brands": [
              "Tofu: Nasoya® Organic Extra Firm"
        ],
        "tools": [
            "Skillet: GreenPan® Non-Stick Frypan",
            "Spatula: OXO® Silicone Spatula",
            "Baking Sheet: Nordic Ware® Half Sheet Pan"
        ],
        "products": [
            "Silicone Spatula Set",
            "Glass Meal Prep Containers"
        ]
    },

    {
        name: "Whole Grain Waffles with Berry Compote 🧇🫐🍯",
        "recipeId": "waffles-with-berry-compote",
        "story": "A hearty, nutritious take on classic waffles, using whole grains and plant-based ingredients for a wholesome yet delicious breakfast.",
        "ingredients": [
            "1 cup (120 g) whole wheat flour",
            "2 tbsp (14 g) wheat germ",
            "2 tbsp (14 g) ground flaxseed",
            "1 tsp baking powder",
            "1/4 tsp salt",
            "1 large egg (or egg substitute, see notes)",
            "3/4 cup (180 ml) pea milk",
            "1 tbsp (15 ml) maple syrup or honey",
            "1/2 tsp vanilla extract (optional)",
            "1 tbsp (15 ml) melted vegan butter"
        ],
        "instructions": [
            "Preheat your waffle maker according to manufacturer instructions.",
            "In a mixing bowl, whisk together whole wheat flour, wheat germ, flaxseed, baking powder, and salt.",
            "In a separate bowl, whisk together the egg, pea milk, maple syrup, vanilla extract (if using), and melted vegan butter.",
            "Gradually mix the wet ingredients into the dry ingredients, stirring until just combined.",
            "Pour the batter into the preheated waffle maker and cook until golden brown and crisp.",
            "Serve warm with berry compote."
        ],
        "subRecipes": [
            {
            "name": "Berry Compote",
            "ingredients": [
                "1 cup (150 g) frozen or fresh mixed berries (e.g., blueberries, raspberries, strawberries)",
                "1 tbsp (15 ml) maple syrup",
                "1/2 tsp lemon juice",
                "1/4 tsp vanilla extract (optional)"
            ],
            "instructions": [
                "In a small saucepan over medium heat, combine the mixed berries, maple syrup, and lemon juice.",
                "Stir occasionally and simmer for 5-7 minutes until the berries soften and release their juices.",
                "For extra flavor, add the vanilla extract (optional) and stir well.",
                "Let the compote cool slightly before serving over waffles."
                ]
            }
        ],
          "notes": [
            "For a shortcut, I usually just use 1 cup Kodiak® Power Cakes mix and 3/4 cup pea milk.",
            "Egg-Free Option: Replace the egg with 1 tbsp ground flaxseed mixed with 3 tbsp water (let sit for 5 minutes to thicken), 1/4 cup unsweetened applesauce, or a store-bought egg replacer (such as liquid plant-based egg substitutes or powdered egg alternatives like those made by Bob’s Red Mill).",
            "Using frozen berries for the compote is convenient and works just as well as fresh.",
            "For added texture, leave some whole berries in the compote or mash them lightly with a fork.",
            "Adjust the sweetness of the compote by adding more or less maple syrup to taste."
        ],
        "brands": [
            "Waffle Mix: Kodiak® Power Cakes",
            "Pea Milk: Ripple® or Sproud®"
        ]
    },
    {
        name: "Whole Wheat Biscuits with Mushrooms, Gravy, and Pineapple 🍄🍞🍍",
        "recipeId": "whole-wheat-biscuits",
        "story": "A hearty, savory breakfast with a touch of sweetness. These whole wheat biscuits are topped with a rich mushroom gravy and served alongside caramelized grilled pineapple for a satisfying balance of flavors.",
        "ingredients": [
            "2 cups (240 g) whole-wheat flour",
            "4 tsp (16 g) baking powder",
            "1/2 tsp salt",
            "4 tbsp (56 g) vegan butter",
            "1 cup (240 ml) pea milk"
        ],
        "instructions": [
            "Preheat oven to 450°F (230°C).",
            "In a large bowl, mix whole-wheat flour, baking powder, and salt.",
            "Cut in the vegan butter until the mixture resembles coarse crumbs.",
            "Gradually stir in pea milk until just combined.",
            "Turn dough onto a lightly floured surface, knead gently, and roll out to 3/4-inch thickness.",
            "Cut into biscuits and place on a baking sheet.",
            "Bake for 10-12 minutes until golden brown.",
            "Serve biscuits topped with sautéed mushrooms and mushroom gravy, with grilled pineapple on the side."
        ],
        "subRecipes": [
            {
                "name": "Mushroom Gravy",
                "ingredients": [
                    "2 tbsp (30 g) vegan butter",
                    "2 cups (340 g) mushrooms, wiped clean and sliced",
                    "2 tbsp (16 g) whole-wheat flour",
                    "1 1/2 cups (360 ml) vegetable broth",
                    "1/2 tsp garlic powder",
                    "1/4 tsp black pepper",
                    "1 tbsp (15 ml) soy sauce or tamari (optional for umami)"
                ],
                "instructions": [
                    "In a pan over medium heat, melt vegan butter and sauté mushrooms until softened (5-7 minutes).",
                    "Sprinkle in whole-wheat flour and stir continuously for 1-2 minutes.",
                    "Slowly add vegetable broth, whisking to prevent lumps.",
                    "Stir in garlic powder, black pepper, and soy sauce (if using).",
                    "Simmer until thickened, about 3-5 minutes.",
                    "Serve over biscuits."
                ]
            },
            {
                "name": "Grilled Pineapple",
                "ingredients": [
                    "4 pineapple slices (1/2-inch thick)",
                    "1 tsp coconut sugar or maple syrup (optional)"
                ],
                "instructions": [
                    "Preheat a grill or grill pan over medium heat.",
                    "Optionally, sprinkle pineapple slices with coconut sugar or maple syrup and cinnamon.",
                    "Grill each slice for 2-3 minutes per side until caramelized.",
                    "Serve warm alongside biscuits and gravy."
                ]
            }
        ],
        "shortcuts": [
            "Use a store-bought vegan gravy mix instead of making it from scratch.",
            "Use frozen pineapple if fresh pineapple isn’t available.",
            "If you don’t have a grill, sear pineapple in a skillet over medium-high heat until caramelized."
        ],
        "plantBased": [
            "Replace vegan butter with olive oil in the biscuits and gravy.",
            "For a soy-free version, use coconut aminos instead of soy sauce in the gravy."
        ],
        "notes": [
            "If you can’t find pineapple, serve biscuits and gravy with fresh berries or another fruit for a sweet contrast.",
            "For extra flavor, add a pinch of smoked paprika to the gravy.",
            "Leftover biscuits can be stored in an airtight container for up to 3 days or frozen for longer storage."
        ],
        "brands": [
             "Pea Milk: Ripple® or Sproud®"
        ],
        "tools": [
            "Biscuit Cutter: OXO® Good Grips Biscuit Cutter Set",
            "Grill Pan: Lodge® Cast Iron Grill Pan",
            "Whisk: OXO® Stainless Steel Whisk"
        ]
    },
];

export default recipes;