const tuesdayDinners = [
    {
        number: 1,
        name: "Stuffed Poblano Peppers with Mole Sauce 🌶️🍫✨",
        recipeId: "stuffed-poblano-peppers",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 2,
        name: "Fajitas 🌯🌶️🥑",
        "recipeId": "fajitas",
        "ingredients": [
            "Whole wheat tortillas (store-bought)",
            "1 red bell pepper, sliced into thin strips",
            "1 green bell pepper, sliced into thin strips",
            "1 yellow bell pepper, sliced into thin strips",
            "1 medium onion, sliced into thin strips",
            "1 jalapeño, sliced (optional)",
            "1 tbsp olive oil or avocado oil",
            "8 oz (225 g) vegan meat (steak or chicken style, not breaded), or 1 1/2 cups cooked black beans",
            "1 tsp smoked paprika",
            "1 tsp cumin",
            "1/2 tsp garlic powder",
            "1/4 tsp chili powder",
            "Salt and black pepper to taste",
            "Juice of 1 lime (fresh when possible, for finishing)",
            "1/2 cup shredded lettuce (optional, topping)",
            "1/2 cup salsa (optional, topping)",
            "1/4 cup shredded cheese (optional, topping, use sparingly)",
            "1/2 avocado, sliced (optional, topping)"
        ],
        "subRecipes": [
            {
                "name": "Yogurt Sauce (sour cream style)",
                "ingredients": [
                    "1/2 cup plain yogurt (or non-dairy yogurt)",
                    "1 tbsp lemon juice",
                    "1/2 tsp onion powder",
                    "Salt to taste"
                ],
                "instructions": [
                    "In a small bowl, whisk together plain yogurt, lemon juice, onion powder, and salt until smooth.",
                    "Adjust seasoning as needed and refrigerate until ready to serve."
                ]
            }
        ],
        "instructions": [
            "Heat 1 tbsp olive or avocado oil in a large pan over medium-high heat.",
            "Sauté sliced peppers and onions for 5-7 minutes until softened and slightly caramelized.",
            "Push vegetables to the side and add vegan meat to the pan. Cook according to package instructions, seasoning with smoked paprika, cumin, garlic powder, onion powder, chili powder, salt, and black pepper.",
            "Finish by squeezing fresh lime juice over the cooked mixture and stirring to combine.",
            "Warm tortillas on a dry pan over medium heat for about 30 seconds per side, or in a 350°F (175°C) oven for 5 minutes.",
            "Assemble fajitas: Layer tortillas with cooked vegetables and vegan meat.",
            "Top with diced tomatoes, shredded lettuce, jalapeños, salsa, cheese, and avocado as desired.",
            "Drizzle with the yogurt sauce before serving."
        ],
        "notes": [
            "For a whole-food option, substitute the vegan meat with 1 1/2 cups of cooked and rinsed black beans. Lightly mash beans with a fork or spatula, then Warm beans with the seasoning blend for extra flavor. Lightly mashing beans helps them hold together in the fajita.",
            "Use cheese sparingly to keep the fajitas heart healthy.",
            "For extra spice, add more chili powder or cayenne.",
            "Sautéing the veggies until slightly charred enhances the smoky fajita flavor.",
            "If you have picky eaters, omit the onion and replace it with 1 tsp onion powder, plus add 2 cloves of minced garlic (instead of garlic powder) to the sliced peppers for extra flavor.",
            "The optional toppings include diced tomatoes, shredded lettuce, salsa, cheese, avocado, and yogurt sauce—add as desired.",
            "Fresh lime juice at the end brightens up the flavors and enhances the dish.",
            "For best texture, slice bell peppers and onions into thin strips to allow even cooking and a traditional fajita experience.",
            "A pinch of sweetness: Add 1/2 tsp sugar or sweetener of your choice when seasoning the vegan meat or beans to enhance flavor balance.",
            "Flavor Boost: Add 1/4 tsp oregano for a more herby, chicken-style flavor, or 1/4 tsp cayenne pepper for extra heat in the steak-style version."
        ]
    },
    {
        number: 3,
        name: "Taco Salad 🥗🌮🫘",
        recipeId: "taco-salad",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 4,
        name: "Ropa Vieja 🇨🇺🍅🌶️",
        recipeId: "ropa-vieja",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 5,
        name: "Pupusas 🇸🇻🫓🧀",
        recipeId: "pupusas",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 6,
        name: "Tacos 🌮💃🌱",
        recipeId: "tacos",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 7,
        name: "Tamales 🍽️🌽🎁",
        recipeId: "tamales",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 8,
        name: "Empanadas with Side of Gazpacho 🥟🍅❄️",
        recipeId: "empanadas-gazpacho",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 9,
        name: "Susan’s Enchilada-Inspired Bake 🧡🧄🫓",
        recipeId: "susans-enchilada-bake",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 10,
        name: "Nachos 🧀🌶️🫘",
        recipeId: "nachos",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 11,
        name: "Green Enchiladas 🌿🫓🧅",
        recipeId: "green-enchiladas",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 12,
        name: "Burritos 🌯🫘🥗",
        recipeId: "burritos",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    }
];

export default tuesdayDinners;
