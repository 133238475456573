import React from 'react';
import styled from 'styled-components';

const Title = styled.h1`
  font-size: 36px;
  color: #80CC28;
  margin-bottom: 20px;
`;

const ThemeTitle = styled.h2`
  font-size: 24px;
  color: #FFA706;
  margin-bottom: 10px;
  text-transform: uppercase;
`;

const SectionTitle = styled.h2`
  font-size: 28px;
  margin-top: 30px;
  margin-bottom: 10px;
  color: #80CC28;
`;

// Modify ChunkTitle to accept a color prop.
const ChunkTitle = styled.h3`
  font-size: 22px;
  color: ${props => props.color || "#FFA706"};
  margin-top: 20px;
  margin-bottom: 10px;
`;

const Paragraph = styled.p`
  font-size: 18px;
  color: #5E2590;
  line-height: 1.4;
  margin-bottom: 20px;
  font-style: italic;
`;

const List = styled.ul`
  list-style-type: disc;
  padding-left: 20px;
  color: #5E2590;
  font-size: 18px;

  li {
    margin-bottom: 5px;
  }
`;

const OrderedList = styled.ol`
  padding-left: 20px;
  color: #5E2590;
  font-size: 18px;

  li {
    margin-bottom: 5px;
  }
`;

const PageContainer = styled.div`
  padding: 60px 80px;
  background: white;
  margin: 0 auto;
  font-family: 'Arial', sans-serif;
  overflow-y: auto;
  color: #5E2590;
  height: calc(100vh - 60px);
  box-sizing: border-box;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    align-items: center;
    padding: 30px 20px;
    margin-bottom: 195px;
  }
`;

const RecipeComponent = ({ recipe, theme }) => {
    let currentChunk = null;

    return (
        <PageContainer>
            {theme && <ThemeTitle>{theme}</ThemeTitle>}
            <Title>{recipe.name}</Title>
            {recipe.story && <Paragraph>{recipe.story}</Paragraph>}

            <SectionTitle>Preparation</SectionTitle>
            <Paragraph>
                Before starting, gather all ingredients, clean your tools, and clear a workspace. This will help ensure a smooth cooking experience.
            </Paragraph>

            {recipe.prep && recipe.prep.length > 0 && (
                <List>
                    {recipe.prep.map((step, index) => (
                        <li key={index}>{step}</li>
                    ))}
                </List>
            )}

            <SectionTitle>Ingredients</SectionTitle>
            <List>
                {recipe.ingredients.map((ingredient, index) => (
                    <li key={index}>{ingredient}</li>
                ))}
            </List>

            <SectionTitle>Instructions</SectionTitle>
            <OrderedList>
                {recipe.instructions.map((instruction, index) => {
                    if (instruction.startsWith('Chunk:')) {
                        currentChunk = instruction.replace('Chunk: ', '');
                        return <ChunkTitle key={index}>{currentChunk}</ChunkTitle>;
                    }
                    return <li key={index}>{instruction}</li>;
                })}
            </OrderedList>

            {recipe.subRecipes && recipe.subRecipes.length > 0 && (
                <>
                    <SectionTitle>Sub-Recipes</SectionTitle>
                    {recipe.subRecipes.map((subRecipe, index) => (
                        <div key={index}>
                            <h3>{subRecipe.name}</h3>
                            {subRecipe.prep && subRecipe.prep.length > 0 && (
                                <>
                                    <h4>Preparation</h4>
                                    <List>
                                        {subRecipe.prep.map((step, i) => (
                                            <li key={i}>{step}</li>
                                        ))}
                                    </List>
                                </>
                            )}
                            <h4>Ingredients</h4>
                            <List>
                                {subRecipe.ingredients.map((ingredient, i) => (
                                    <li key={i}>{ingredient}</li>
                                ))}
                            </List>
                            <h4>Instructions</h4>
                            <OrderedList>
                                {subRecipe.instructions.map((instruction, i) => (
                                    <li key={i}>{instruction}</li>
                                ))}
                            </OrderedList>
                        </div>
                    ))}
                </>
            )}

            {recipe.optionalToppings && recipe.optionalToppings.length > 0 && (
                <>
                    <SectionTitle>Optional Toppings</SectionTitle>
                    <List>
                        {recipe.optionalToppings.map((topping, index) => (
                            <li key={index}>{topping}</li>
                        ))}
                    </List>
                </>
            )}

            {recipe.notes && recipe.notes.length > 0 && (
                <>
                    <SectionTitle>Notes</SectionTitle>
                    <List>
                        {recipe.notes.map((note, index) => (
                            <li key={index}>{note}</li>
                        ))}
                    </List>
                </>
            )}

            {recipe.portionOptions && recipe.portionOptions.length > 0 && (
                <>
                    <SectionTitle>Portion Options</SectionTitle>
                    <List>
                        {(() => {
                            // Counter for "Helping:" headings
                            let helpingCount = 0;
                            return recipe.portionOptions.map((option, index) => {
                                if (option.startsWith('Helping:')) {
                                    const currentHelping = option.replace('Helping: ', '');
                                    let currentColor;
                                    if (helpingCount === 0) {
                                        currentColor = "#FF00FF"; // Magenta for the first instance
                                    } else if (helpingCount === 1) {
                                        currentColor = "#800080"; // Dark purple for the second
                                    } else if (helpingCount === 2) {
                                        currentColor = "#0000FF"; // Blue for the third
                                    } else {
                                        currentColor = "#FFA706"; // Fallback to default
                                    }
                                    helpingCount++;
                                    return (
                                        <ChunkTitle key={index} color={currentColor}>
                                            {currentHelping}
                                        </ChunkTitle>
                                    );
                                }
                                return <li key={index}>{option}</li>;
                            });
                        })()}
                    </List>
                </>
            )}

            {recipe.prepAhead && recipe.prepAhead.length > 0 && (
                <>
                    <SectionTitle>Prep Ahead (Optional)</SectionTitle>
                    <List>
                        {recipe.prepAhead.map((step, index) => (
                            <li key={index}>{step}</li>
                        ))}
                    </List>
                </>
            )}

            {recipe.brands && recipe.brands.length > 0 && (
                <>
                    <SectionTitle>Recommended Brands</SectionTitle>
                    <Paragraph>
                        These are brands I personally use or have found helpful. This list isn�t meant to be exhaustive�just a guide to help you find quality ingredients. While I try to research company ethics, I can't guarantee any brand�s practices.
                    </Paragraph>
                    <List>
                        {recipe.brands.map((brand, index) => (
                            <li key={index}>{brand}</li>
                        ))}
                    </List>
                </>
            )}

            {recipe.products && recipe.products.length > 0 && (
                <>
                    <SectionTitle>Products I Like</SectionTitle>
                    <List>
                        {recipe.products.map((product, index) => (
                            <li key={index}>{product}</li>
                        ))}
                    </List>
                </>
            )}
        </PageContainer>
    );
};

export default RecipeComponent;
