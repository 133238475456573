import React from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import breakfasts from '../../data/breakfasts';
import sundayDinners from '../../data/Sunday';
import mondayDinners from '../../data/Monday';
import tuesdayDinners from '../../data/Tuesday';
import wednesdayDinners from '../../data/Wednesday';
import thursdayDinners from '../../data/Thursday';
import fridayDinners from '../../data/Friday';
import saturdayDinners from '../../data/Saturday';
import RecipeComponent from '../../components/recipe'; // Ensure this path matches your structure

const PageContainer = styled.div`
  padding: 20px 20px 20px;
  margin: 0 auto;
  font-family: 'Arial', sans-serif;
  color: #CCC;
  overflow-y: auto;
  height: calc(100vh - 60px);
  box-sizing: border-box;
`;

const Title = styled.h1`
  font-size: 36px;
  color: #80CC28;
  margin-bottom: 20px;
`;

const mealThemes = {
    breakfasts: "Breakfast",
    sundayDinners: "Dinners - Comfort, Soul Food, Cajun, or African Sunday",
    mondayDinners: "Dinners - Sandwich Monday",
    tuesdayDinners: "Dinners - Hispanic Tuesday",
    wednesdayDinners: "Dinners - Middle Eastern and Mediterranean Wednesday",
    thursdayDinners: "Dinners - Italian, French, or German Thursday",
    fridayDinners: "Dinners - Asian Friday",
    saturdayDinners: "Dinners - Eat When You Want Saturday"
};

// Map category to corresponding recipe files
const recipeFiles = {
    breakfasts,
    sundayDinners,
    mondayDinners,
    tuesdayDinners,
    wednesdayDinners,
    thursdayDinners,
    fridayDinners,
    saturdayDinners,
};

function RecipePage() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    // Retrieve both `recipeId` and `category` from the query string
    const recipeSlug = queryParams.get('recipeId');
    const category = queryParams.get('category');

    const recipes = recipeFiles[category]; // Dynamically load recipes based on category
    const recipe = recipes ? recipes.find((r) => r.recipeId === recipeSlug) : null;
    const theme = mealThemes[category] || ""; // Get the meal theme

    if (!recipe) {
        return (
            <PageContainer>
                <Title>Recipe Not Found</Title>
                <p>Please select a valid recipe from the menu.</p>
            </PageContainer>
        );
    }

    return (
        <PageContainer>
            <RecipeComponent recipe={recipe} theme={theme} />
        </PageContainer>
    );
}

export default RecipePage;
