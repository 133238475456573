const wednesdayDinners = [
    {
        number: 1,
        name: "Kabobs 🍢🌶️🥬",
        recipeId: "kabobs",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 2,
    "name": "Fasolakia Lathera: Greek Green Beans 🇬🇷🫘🥔",   
    "recipeId": "fasolakia-lathera",
    "story": "A cozy, nourishing one-pot Greek classic featuring green beans, potatoes, and a bright tomato sauce, now with creamy white kidney beans stirred in at the end for extra plant-based protein and creaminess.",
           "prep": [
        "Chop onion and mince garlic.",
        "Halve baby potatoes.",
        "Trim green beans if using fresh.",
        "Dice tomatoes."
    ],   
     "ingredients": [
        "4 tbsp olive oil (calculate ml)",
        "1 large onion, chopped",
        "5 cloves garlic, minced",
        "16 oz (450g) baby potatoes, halved",
        "20 oz fresh or frozen green beans, trimmed (560g)",
        "4 large tomatoes, diced (about 24-28 oz or 680-800g)",
        "2 tbsp tomato paste (calculate ml)",
        "1 1/2 tsp dried oregano (calculate ml)",
        "3/4 tsp dried thyme (calculate ml)",
        "3/4 tsp smoked paprika (calculate ml)",
        "2 bay leaves",
        "Salt and black pepper to taste",
        "2 cups vegetable broth (calculate grams?)",
        "Juice of 1 lemon, divided",
        "1/3 cup fresh parsley, chopped (for serving, plus 1 tbsp stirred in at the end)    (calculate grams?)",
        "2 cups cooked white kidney beans (cannellini beans), about 18 oz (510g)",
        "3 tbsp Feta, (about 1.5 ounces, 42g)",
        "Whole-grain pitas or bread (warmed, for serving)"
    ],
    "instructions": [
        "Chunk: Sauté the Aromatics",
        "Heat olive oil in a large pot over medium heat.",
        "Add the chopped onion and sauté for 7-8 minutes until golden brown.",
        "Stir in the garlic and cook for another 30 seconds until fragrant.",
        "Add the tomato paste and cook for 1 minute, stirring frequently, to deepen its flavor.",

        "Chunk: Simmer the Potatoes",
        "Add the halved baby potatoes and cook for 5 minutes, stirring occasionally.",
        "Mix in diced tomatoes, oregano, thyme, smoked paprika, bay leaf, salt, and black pepper.",
        "Pour in vegetable broth and bring to a gentle simmer.",
        "Cover and simmer on low heat for about 25 minutes, stirring occasionally, until the potatoes begin to soften.",

        "Chunk: Add the Green Beans",
        "Add the trimmed green beans, stir well, and continue simmering for another 20 minutes, covered.",

        "Chunk: Finish the Dish",
        "Mash the white kidney beans slightly with a fork or spoon and add them to the pot along with half of the lemon juice.",
        "Simmer for another 10 minutes, stirring gently.",
        "Remove the bay leaf and stir in 1 tbsp of fresh parsley.",
        "Adjust seasoning and add the remaining lemon juice to taste.",
        "Serve warm, topped with remaining parsley, optional feta, alongside whole-grain pita or bread."
         ],
        "subRecipes": [],
        "plantBased": [
            "Use vegan feta instead of feta or sliced Kalamata olives for a briny, umami-rich flavor.",
        ],
            "optionalToppings": [
              ],
        "notes": [
            "Sautéing the tomato paste before adding liquid enhances its richness and removes any raw taste.",
            "Layering the lemon juice—adding half during cooking and half at the end—creates a balanced acidity without overpowering the dish.",
            "Baby potatoes work well in this dish, but you can also use chopped Yukon Gold or red potatoes.",
            "If the sauce reduces too much, add a little more water as needed.",
               ],
        "portionOptions": [
            "Helping: Single Serving (~600 calories): Scaled ingredient list for one person.",
            "1 tbsp olive oil (calculate ml)",
            "1/3 medium onion, chopped",
            "1 or 2 cloves garlic, minced",
            "4 oz (110g) baby potatoes, halved",
            "6 ounces  (170g) fresh or frozen green beans, trimmed",
            "1 large tomato, diced (about 8 oz or 220g)",
            "1/2 tbsp tomato paste (calculate ml)",
            "1/2 tsp dried oregano (calculate ml)",
            "1/4 tsp dried thyme (calculate ml)",
            "1/4 tsp smoked paprika (calculate ml)",
            "1 bay leaf",
            "Salt and black pepper to taste",
            "1/2 cup vegetable broth (calculate grams?)",
            "Juice of (most of) 1/2 lemon, divided",
            "2 tbsp fresh parsley, chopped (for serving, plus 1 tbsp stirred in at the end)  (calculate ml)",
            "4 oz cooked white kidney beans (cannellini beans)  (calculate grams?)",
            "3/4 tbsp Feta (optional, for serving, use sparingly  (calculate grams?))",
            "To increase calories a bit, double the cannellini beans.",
            "1 medium Whole-grain pita or bread (warmed, for serving)",
            "Helping: Family Size (~2400 calories total): Scaled ingredient list for 3-4 people. Main recipe, assumes 4 medium whole wheat pitas.",
            "Helping: Big Batch (~3600 calories total): Scaled ingredient list, serves 4-6 people. Increase main recipe by 50%."
        ],
        "prepAhead": [
            "Chop onion and garlic in advance.",
            "Halve baby potatoes and store in water in the fridge.",
            "Trim and store green beans.",
            "Dice tomatoes and refrigerate."

        ]
    },
    {
        number: 3,
        name: "Red Lentil Soup and Veggie Tartine 🍅🍞🥕",
        recipeId: "red-lentil-soup-veggie-tartine",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 4,
        name: "Lemon Orzo Soup 🍋🥣🌿",
        recipeId: "lemon-orzo-soup",
        ingredients: [
            "6 cups no-chicken broth",
            "2 small onions, diced",
            "2 medium carrots, diced",
            "1 celery stalk, diced",
            "6 tbsp freshly squeezed lemon juice",
            "2 tbsp olive oil",
            "15 oz. cup white beans, drained and rinsed",
            "6 eggs, beaten",
            "2 cups Ripple (pea milk)",
            "1 cup whole-grain rice or whole-wheat orzo",
            "1 tsp salt, or to taste",
            "2 tsp pepper, or to taste"
        ],
        instructions: [
            "In a large pot, heat the olive oil over medium heat. Add the diced onions, carrots, and celery. Sauté until softened, about 5 minutes.",
            "Add the no-chicken broth and bring to a boil. Stir in the whole-grain rice or orzo, reduce heat to a simmer, and cook until tender, about 15-20 minutes.",
            "In a medium bowl, whisk together the eggs, Ripple, and lemon juice until well combined.",
            "Slowly ladle a cup of hot broth into the egg mixture while whisking constantly to temper the eggs. Repeat with another cup of broth.",
            "Gradually pour the tempered egg mixture back into the soup, stirring constantly, to thicken the soup without curdling the eggs.",
            "Add the white beans and cook for an additional 5 minutes to heat through.",
            "Season with salt and pepper to taste.",
            "Serve warm, garnished with lots of fresh chopped dill or parsley, lemon wedges, and optional crumbled feta."
        ],
        notes: [
            "For a vegan version, substitute eggs with a vegan egg replacer and use a plant-based feta alternative.",
            "This soup is great for meal prep and tastes even better the next day.",
            "Optional Garnish ideas: Lots of fresh chopped dill or parsley, fresh lemon wedges, feta or vegan feta."
        ],
    },
    {
        number: 5,
        name: "Susan’s Shawarma 🌯✨🥗",
        recipeId: "susans-shawarma",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 6,
        name: "Dal Makhani 🇮🇳🫘🌶️",
        recipeId: "dal-makhani",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 7,
        name: "Shakshuka 🍳🍅🌶️",
        recipeId: "shakshuka",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 8,
        name: "Falafel, Garlicky Hummus & Mediterranean Salad 🧆🧄🥒",
        recipeId: "falafel-hummus-mediterranean-salad",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 9,
        name: "Buffalo Seitan, Freekah, and Salad 🌶️🌾🥗",
        recipeId: "buffalo-seitan-freekah-salad",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 10,
        name: "Spanakotashen and Black Lentil Salad 🥬🥟🖤",
        recipeId: "spanakotashen-black-lentil-salad",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 11,
        name: "Ful 🇪🇬🫘🌶️",
        recipeId: "ful",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 12,
        name: "Mujaddara 🧅🍚🟤",
        recipeId: "mujaddara",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    }
];

export default wednesdayDinners;
