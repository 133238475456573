const thursdayDinners = [
    {
        number: 1,
        name: "Polenta or Barley and Roasted Veggies 🍅🫑🌽🌿",
        recipeId: "polenta-barley-roasted-veggies",
        ingredients: [
            "3 tbsp olive oil",
            "1 tsp Italian seasoning",
            "1/2 tsp garlic powder",
            "1/2 tsp smoked paprika",
            "1/4 tsp crushed red pepper flakes (optional, for heat)",
            "1/2 tsp salt",
            "1 cup zucchini, cut into 1-inch chunks",
            "1 cup summer squash, cut into 1-inch chunks",
            "1 cup cherry tomatoes",
            "1 cup asparagus, cut into 1-inch chunks",
            "1 cup mushrooms, halved",
            "For the Parmesan Polenta:",
            "3 cups water",
            "1 cup milk (or plant-based milk)",
            "1 cup coarse or medium-grit cornmeal",
            "3 tbsp butter, divided",
            "1/2 cup grated Parmesan cheese (or nutritional yeast for vegan option)",
            "1 1/2 tsp salt",
            "1/2 tsp garlic powder",
            "For the Barley Option:",
            "1 cup pearl barley",
            "3 cups vegetable broth or water",
            "1 bay leaf (optional)"
        ],
        instructions: [
            "Preheat the oven to 425°F.",
            "Place the zucchini, summer squash, cherry tomatoes, asparagus, and mushrooms on a parchment-lined baking sheet.",
            "In a small bowl, mix olive oil, Italian seasoning, garlic powder, smoked paprika, crushed red pepper flakes (if using), and salt. Drizzle the mixture over the vegetables and toss to coat evenly.",
            "Roast the vegetables for 20-25 minutes, stirring halfway through, until tender and slightly caramelized.",
            "For the Parmesan Polenta: In a heavy-bottomed pan or Dutch oven, bring the water and milk to a boil. Gradually whisk in the cornmeal, reduce heat to a simmer, and cover. Cook for 25-30 minutes, stirring occasionally to prevent sticking or burning.",
            "Once the polenta is thick and cooked, remove from heat. Stir in Parmesan cheese, 2 tbsp butter, garlic powder, and salt until well combined. Adjust seasoning to taste.",
            "For the Barley Option: Rinse the barley under cold water. In a saucepan, combine barley, vegetable broth (or water), and bay leaf. Bring to a boil, then reduce heat to a simmer. Cover and cook for 25-30 minutes, or until tender and liquid is absorbed. Discard the bay leaf and fluff the barley with a fork.",
            "To serve: Place the polenta or barley in the bottom of a bowl. Top with roasted vegetables and garnish with a drizzle of olive oil, balsamic vinegar, or fresh herbs like parsley or basil."
        ],
        notes: [
            "For a vegan option, replace Parmesan with nutritional yeast and butter with vegan butter.",
            "Add more flavor by sprinkling the roasted vegetables with lemon zest or a squeeze of fresh lemon juice before serving.",
            "For a heartier version, add roasted chickpeas or white beans to the vegetables."
        ]
    },
    {
        number: 2,
        name: "Angel Hair Pesto with Tomatoes on the Side 🍃🍝🍅",
        recipeId: "angel-hair-pesto-tomatoes",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
          },
    {
        number: 3,
        name: "Spaghetti with Lentilballs and Side Italian Salad 🍝🧆🥗",
        recipeId: "spaghetti-lentilballs-italian-salad",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 4,
        name: "French Onion Soup with Roasted Vegetable Salad 🧅🥣🥬",
        recipeId: "french-onion-soup-vegetable-salad",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 5,
        name: "Pizza 🍕🫑🧀",
        recipeId: "pizza",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 6,
        name: "Lasagna 🧄🧀🍅",
        recipeId: "lasagna",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 7,
        name: "TVP Bolognese 🌾🍝🍅",
        recipeId: "tvp-bolognese",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 8,
        name: "French Lentils with Provençal New Potatoes 🇫🇷🟤🥔🌿",
        recipeId: "french-lentils-provencal-potatoes",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 9,
        name: "Butternut Squash Pitta Pizza 🎃🫓🧀",
        recipeId: "butternut-squash-pitta-pizza",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 10,
        name: "Mushroom Risotto 🍄🍚🧅",
        recipeId: "mushroom-risotto",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 11,
        name: "Vegan Schnitzel with Spargle and Potatoes 🇩🇪🥔🌱",
        recipeId: "vegan-schnitzel-spargle-potatoes",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 12,
        name: "Tuscan White Bean & Vegetable Stew 🍲🫘🌿",
        "recipeId": "tuscan-white-bean-stew",
        "story": "A hearty, nutrient-dense stew inspired by traditional Tuscan flavors, packed with fiber, protein, and wholesome ingredients for a balanced meal.",
        "ingredients": [
            "2 tbsp extra virgin olive oil",
            "1 large onion, diced",
            "2 medium carrots, peeled and diced",
            "2 celery ribs, diced",
            "4 garlic cloves, finely chopped",
            "½ tsp dried oregano",
            "½ tsp dried basil",
            "¼ tsp red chili flakes",
            "2 tbsp tomato paste",
            "2 (15-oz / 425 g) cans cannellini beans, drained and rinsed",
            "1 (28-oz / 800 g) can crushed tomatoes",
            "1 cup (240 ml) vegetable broth",
            "4 oz (120 g) fresh baby spinach or chopped kale",
            "Salt and black pepper to taste",
            "1 tbsp nutritional yeast",
            "¼ cup (4 g) fresh parsley, chopped",
            "1 tsp fresh rosemary, minced (or ½ tsp dried)",
            "1 bay leaf",
            "1 tbsp balsamic vinegar"
        ],
        "instructions": [
            "Heat olive oil in a large pot over medium heat. Add onion, carrots, and celery, cooking for 6-8 minutes until softened.",
            "Stir in garlic, oregano, basil, red chili flakes, and tomato paste. Cook for another minute, stirring often.",
            "Add the cannellini beans, crushed tomatoes, vegetable broth, bay leaf, rosemary, and nutritional yeast. Bring to a simmer and let cook for 15-20 minutes.",
            "Stir in spinach or kale and let it wilt into the stew. Add balsamic vinegar and adjust seasoning with salt and black pepper.",
            "Remove the bay leaf, garnish with fresh parsley, and serve warm."
        ],
        "notes": [
            "For a richer texture, mash some of the beans before adding them to the stew.",
            "If you prefer a smoky flavor, add ½ tsp smoked paprika.",
            "For extra heartiness, stir in ½ cup cooked farro or barley."
        ]
    }

];

export default thursdayDinners;
