const sundayDinners = [
    {
        number: 1,
        name: "Vegan Noodle Soup 🍜🌱🥕",
        recipeId: "vegan-noodle-soup",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 2,
        name: "Lentil, Mushroom, Walnut Loaf with Side of Mashed Potatoes 🍄🟤🥔",
        recipeId: "lentil-mushroom-walnut-loaf",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 3,
        name: "Jambalaya 🇺🇸🌶️🍚",
        recipeId: "jambalaya",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 4,
        name: "Cabbage Rolls 🥬🍅🥣",
        recipeId: "cabbage-rolls",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 5,
        name: "West African Peanut Stew 🌍🥜🍲",
        recipeId: "west-african-peanut-stew",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 6,
        name: "New Year’s Dinner 🎉🥬🧧",
        recipeId: "new-years-dinner",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 7,
        name: "Vegan Cajun Broil 🌶️🌽🫑",
        recipeId: "vegan-cajun-broil",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 8,
        name: "Shepherd’s Pie 🥧🌱🥔",
        recipeId: "shepherds-pie",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 9,
        name: "Ethiopian Teff and Vegetables 🇪🇹🌾🥬",
        recipeId: "ethiopian-teff-and-vegetables",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 10,
        name: "Stuffed Bell Peppers 🌶️🍚🧄",
        recipeId: "stuffed-bell-peppers",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 11,
        name: "Nigerian Jollof Rice, Moin Moin, and Pounded Yam 🇳🇬🍚🥄",
        recipeId: "nigerian-jollof-rice",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 12,
        name: "Jackfruit Stew 🌱🧑🏾‍🍳🍲",
        recipeId: "jackfruit-stew",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    }
];

export default sundayDinners;
