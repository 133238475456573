const saturdayDinners = [
    {
        number: 1,
        name: "My Dad’s Navy Bean Soup",
        recipeId: "navy-bean-soup",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        "name": "Vegetarian Charcuterie Board 🧀🍇🥖",
        "recipeId": "vegetarian-charcuterie-board",
        "story": "This beautiful and balanced vegetarian charcuterie board is perfect for cozy nights, elegant entertaining, or just treating yourself to something special. With proteins, cheeses, briny bites, sweet spreads, and crunchy elements, it's a full meal in snack form—and it's customizable for every season and vibe!",
        "prep": [
            "Slice fruits and cheeses.",
            "Warm veggie sausage and meatballs, if desired.",
            "Arrange small bowls for spreads and relishes.",
            "Lay out board or large serving platter."
        ],
        "ingredients": [
            "1 small French baguette, whole grain or sourdough (200–250g)",
            "1–2 types of interesting crackers (~100g total), e.g., seeded, rosemary, or fig & olive",
            "1 vegan Italian-style sausage, sliced (e.g., Field Roast® ~90g)",
            "3–4 vegan meatballs, warmed (e.g., Gardein® ~120g)",
            "2 types of cheese, ~2 oz each (~56g per type), e.g., cheddar, gouda, or bleu",
            "1 spreadable vegan cheese, such as Miyoko’s® Garlic Herb (~60g)",
            "1/4 cup olive tapenade (60g)",
            "2 tbsp fig jam or fruit preserves (30g)",
            "1 tbsp savory mustard or pesto (15g)",
            "1/4 cup assorted olives (~60g)",
            "1/4 cup pickles or pickled vegetables (60g)",
            "1/4 cup marinated artichoke hearts (~60g)",
            "2 tbsp nuts (almonds, walnuts, or pistachios) (~20g)",
            "2 tbsp dried fruit (figs, apricots, or cranberries) (~20g)",
            "1 cup fresh fruit, such as grapes, apple slices, or berries (~150g)",
            "Optional: Fresh herbs or edible flowers for garnish"
        ],
        "instructions": [
            "Place spreads (tapenade, jam, mustard/pesto) in small ramekins or bowls.",
            "Arrange fruits, nuts, and pickled items in clusters for visual variety.",
            "Slice bread and toast lightly, if desired.",
            "Slice or shape cheeses and arrange them in different corners of the board.",
            "Warm veggie meats if serving hot, and place in a central position.",
            "Fill in gaps with crackers and garnish with herbs or flowers if using.",
            "Serve and enjoy!"
        ],
        "shortcuts": [
            "Use store-bought fig jam, tapenade, and pickled items.",
            "Pre-sliced cheeses or cheese wedges make for faster plating.",
            "Use frozen or pre-cooked veggie meatballs—just heat and serve."
        ],
        "plantBased": [
            "To make fully plant-based: use only vegan cheeses (such as Miyoko’s or Violife®).",
            "Ensure veggie meats are fully plant-based (many are vegetarian but not vegan).",
            "Check that crackers and spreads do not contain dairy or honey."
        ],
        "notes": [
            "You can rotate items seasonally—for example, use peaches and cherries in summer, pears and roasted nuts in fall.",
            "Try roasted red pepper spread, beet hummus, or white bean dip as other savory spread options.",
            "Cut harder cheeses into triangles or cubes for texture contrast.",
            "No board? Use a sheet pan lined with parchment paper—it works great!"
        ],
        "brands": [
            "Vegan Cheese: Miyoko’s®, Violife®, or Treeline®",
            "Vegan Meats: Field Roast®, Gardein®, Beyond Meat®",
            "Crackers: Raincoast Crisps®, Mary’s Gone Crackers®",
            "Tapenade: Find recipe on LovingItVegan.com or use store-bought"
        ],
        "tools": [
            "Serving Board or Large Platter: Bamboo, Slate, or Wood",
            "Small Ramekins or Bowls for Spreads",
            "Cheese Knives or Spreaders",
            "Mini Tongs or Cocktail Picks"
        ],
        "products": [
            "Reusable Food Wraps or Storage Containers for Leftovers",
            "Decorative Serving Boards",
            "Mini Bowls for Dips",
            "Small Cheese Boards for Personal Plates"
        ]
    },
    {
        number: 3,
        name: "Chickpea Pasta Salad",
        recipeId: "chickpea-pasta-salad",
        ingredients: [
            "1 pound whole-grain pasta",
            "1 can chickpeas, drained and rinsed",
            "1/2 cup vegan mayonnaise",
            "2 tbsp salad seasoning (e.g., a healthier equivalent of Salad Supreme)",
            "1 small red onion, diced",
            "2 celery stalks, diced"
        ],
        instructions: [
            "Cook the pasta according to package instructions. Drain and let cool.",
            "In a large bowl, mix the cooked pasta, chickpeas, vegan mayonnaise, salad seasoning, onion, and celery.",
            "Refrigerate for at least an hour before serving to allow flavors to meld."
        ],
        notes: ["Make ahead for convenience."]
    },
    {
        number: 4,
        name: "Chili and Cornbread",
        recipeId: "chili-cornbread",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 5,
        name: "Split Pea Soup with Whole Grain Rolls",
        recipeId: "split-pea-soup-rolls",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 6,
        name: "Cobb Salad & Bruschetta",
        recipeId: "cobb-salad-bruschetta",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 7,
        name: "Barley Salad with Stuffed Tomatoes",
        recipeId: "barley-salad-stuffed-tomatoes",
        ingredients: [
            "2 cups cooked pearl barley",
            "1 cucumber, diced",
            "1 cup cherry tomatoes, halved",
            "1/4 cup red onion, finely diced",
            "1/4 cup fresh parsley, chopped",
            "3 tbsp olive oil",
            "2 tbsp red wine vinegar",
            "1 tsp dried oregano",
            "Salt and pepper to taste",
            "4 large tomatoes (for stuffing)"
        ],
        instructions: [
            "In a large bowl, mix the cooked barley, cucumber, cherry tomatoes, red onion, parsley, olive oil, red wine vinegar, oregano, salt, and pepper.",
            "Slice off the tops of the large tomatoes and scoop out the insides. Reserve the tops for presentation.",
            "Stuff the hollowed-out tomatoes with the barley salad mixture.",
            "Serve immediately or refrigerate until ready to eat."
        ],
        notes: ["A hearty pairing for dinner."]
    },
    {
        number: 8,
        name: "A Base, A Veg, A Protein, & A Sauce!",
        recipeId: "base-veg-protein-sauce",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 9,
        name: "Potato Soup with Chopped Salad",
        recipeId: "potato-soup-chopped-salad",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 10,
        name: "Warm Grain Bowl",
        recipeId: "warm-grain-bowl",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 11,
        name: "Fondue Dinner",
        recipeId: "fondue-dinner",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 12,
        name: "Pumpkin Cornbread Bean Bake",
        recipeId: "pumpkin-cornbread-bean-bake",
        ingredients: [
            "1 tablespoon olive oil",
            "1 large sweet potato, peeled and finely chopped",
            "1 medium onion, coarsely chopped",
            "1 small green pepper, coarsely chopped",
            "1 small sweet red pepper, coarsely chopped",
            "1 can (15 ounces) black beans, rinsed and drained",
            "1 can (15 ounces) pinto beans, rinsed and drained",
            "1 cup frozen corn",
            "1 can (4 ounces) chopped green chiles",
            "1/2 teaspoon salt",
            "1/2 teaspoon ground cumin",
            "1/2 cup vegetable broth",
            "3 ounces cream cheese, softened",
            "TOPPING:",
            "1 package (8-1/2 ounces) cornbread/muffin mix",
            "1 large egg",
            "1/3 cup 2% milk",
            "1/3 cup solid-pack pumpkin"
        ],
        instructions: [
            "Preheat oven to 400°F.",
            "In a large skillet, heat olive oil over medium-high heat. Add sweet potato, onion, and peppers; cook and stir for 5-7 minutes or until the potato is cooked halfway through.",
            "Stir in black beans, pinto beans, corn, green chiles, salt, and cumin; heat through.",
            "Stir in vegetable broth and cream cheese until blended. Transfer the mixture to a greased 13x9-inch baking dish.",
            "In a large bowl, combine cornbread mix, egg, milk, and pumpkin. Mix until smooth.",
            "Spoon the cornbread topping evenly over the bean mixture.",
            "Bake, uncovered, for 25-30 minutes or until a toothpick inserted in the cornbread topping comes out clean."
        ],
        notes: [
            "To make ahead: Prepare the filling as directed, omitting the topping. Cover and refrigerate overnight. Remove from the refrigerator 30 minutes before baking, prepare the topping, and bake as directed.",
            "For a spicier version, add 1/4 teaspoon cayenne pepper or 1 diced jalapeño to the filling mixture."
        ]
    }
];

export default saturdayDinners;
