const mondayDinners = [
    {
        number: 1,
        name: "Bánh Mì 🇻🇳🥖🥕🔥",
        "recipeId": "banh-mi",
        "ingredients": [
            "Bánh Mì rolls from a Vietnamese bakery, hoagie rolls, French baguettes, brioche rolls, or homemade partially whole grain rolls (recipe below)",
            "1 1/2 to 2 medium carrots, julienned, pickled",
            "1 cup daikon radish or matchstick-cut radishes, pickled",
            "1 cucumber, sliced (not pickled, for freshness)",
            "1 red hot chili, thinly sliced (or substitute red onion or jalapeno, pickled)",
            "15.8 oz (450 g) extra firm tofu, pressed and marinated",
            "1 cup (240 mL) boiling water (for pickling vegetables)",
            "1 cup (240 mL) white vinegar (for pickling vegetables)",
            "3 Tbsp (38 g) granulated sugar (for pickling vegetables)",
            "2 tsp (12 g) salt (for pickling vegetables)",
            "6 cloves garlic, crushed (for marinating tofu)",
            "4 Tbsp (60 mL) sodium-reduced soy sauce (for marinating tofu)",
            "4 tsp (20 mL) sriracha hot sauce (for marinating tofu)",
            "1 1/3 tbsp (16 g) granulated sugar (for marinating tofu)",
            "2 tsp (10 mL) sesame oil (for marinating tofu)",
            "1 lime, juiced (for marinating tofu)"
        ],
        "instructions": [
            "Pickle carrots, daikon radish, and jalapeno in a glass jar or container for at least 30 minutes.",
            "Gently press tofu to remove excess water before slicing. Marinate for at least 30 minutes.",
            "Cook tofu in olive oil on medium-high heat for a few minutes on each side. Add freshly cracked black pepper.",
            "Toast the rolls.",
            "Assemble sandwiches with a light amount of mayo, hoisin sauce, and sriracha.",
            "Add tofu and vegetables to the sandwich.",
            "Serve with extra hoisin sauce and sriracha if desired."
        ],
        "notes": [
            "Too much hoisin sauce makes the bánh mì too sweet.",
            "Mushroom pâté or cooked veggie bacon can be added for extra flavor.",
            "Fresh cilantro is a great topping for an herbal touch.",
            "If you don't have hoisin sauce, you can mix soy sauce, rice vinegar, and one or more of molasses, maple syrup, or brown sugar.",
            "A bread machine can be used to knead the dough for convenience, but allow the yeast to activate for 5-10 minutes before adding the ingredients.",
            "Using a good vegetable chopper can speed up prep time and ensure consistent cuts."
        ],
        "subRecipes": [
            {
            "name": "Whole Grain Bánh Mì Roll",
            "ingredients": [
                "2 cups White Whole Wheat Flour",
                "1 cup Spelt Flour",
                "1 cup Bread Flour",
                "1 1/2 cups warm water (adjust as needed)",
                "2 1/4 tsp (1 packet) active dry yeast",
                "1 1/2 tbsp sugar",
                "2 tbsp olive oil",
                "1 1/2 tsp salt",
                "1 tbsp vital wheat gluten (optional)"
            ],
            "instructions": [
                "Activate yeast: Combine warm water, sugar, and yeast. Let sit for 5-10 minutes until foamy.",
                "Mix Dough: In a large bowl, mix flours, salt, and vital wheat gluten. Add yeast mixture and olive oil. Stir until combined.",
                "Knead for about 10 minutes (or 5 minutes in a stand mixer) until soft and elastic.",
                "First Rise: Place dough in an oiled bowl, cover, and let rise for 1-1.5 hours until doubled in size.",
                "Shape Rolls: Punch down the dough and divide into 6-8 pieces. Shape into mini baguettes.",
                "Second Rise: Let shaped rolls rise again for 45 minutes.",
                "Preheat Oven: Preheat to 425°F (220°C) with a pan of water on the bottom rack for steam.",
                "Bake: Score the tops and bake for 20-25 minutes until golden brown.",
                "Cool: Let cool on a wire rack before slicing."
                ]
            }
        ]
    },
    {
        number: 2,
        name: "Chickpea 'Tuna' 🧆🥒🥪",
        recipeId: "chickpea-tuna",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 3,
        name: "Sausage and Peppers (Philly on Calendar) 🌭🫑🧅",
        recipeId: "sausage-and-peppers",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 4,
        name: "Burgers and Potato Salad 🍔🥔🥗",
        recipeId: "burgers-and-potato-salad",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 5,
        name: "Sloppy Joes and Sweet Potato Fries 🌶️🍠🍔",
        recipeId: "sloppy-joes-and-sweet-potato-fries",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 6,
        name: "Chicken Fried Tofu with Veggies and Dip 🍗🌱🥦🥣",
        recipeId: "chicken-fried-tofu",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 7,
        name: "Subs or Wraps with Marinated Vegetable Salad 🌯🥬🍅",
        recipeId: "subs-or-wraps",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 8,
        name: "Hot Dogs, Baked Beans, and Corn 🌭🫘🌽",
        recipeId: "hot-dogs-baked-beans-corn",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 9,
        name: "Greek Hummus Wraps or Pita Pockets 🇬🇷🌯🧄",
        recipeId: "greek-hummus-wraps",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 10,
        name: "BLT’s 🥓🥬🍅",
        recipeId: "blts",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 11,
        name: "Pulled King Oyster Mushrooms and Slaw Sandwiches 🍄🥪🥬",
        recipeId: "pulled-king-oyster-mushrooms",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 12,
        name: "Vegetarian Ruben 🥪🧄🥬",
        recipeId: "vegetarian-ruben",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    }
];

export default mondayDinners;