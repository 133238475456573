const fridayDinners = [
    {
        number: 1,
        name: "Urad Dal with Curried Cauliflower 🇮🇳🫘🥦",
        recipeId: "urad-dal-curried-cauliflower",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 2,
        name: "Thai Green Curry with Spring Vegetables 🇹🇭🍛🥕",
        recipeId: "thai-green-curry-spring-vegetables",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 3,
        name: "Vegetable Biryani 🍚🌶️🌿",
        recipeId: "vegetable-biryani",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 4,
        name: "General Tso’s Tofu 🥡🌶️🍚",
        recipeId: "general-tsos-tofu",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 5,
        name: "Bibimbap 🇰🇷🍳🥬",
        recipeId: "bibimbap",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 6,
        name: "Hot Pot 🍲🔥🥢",
        recipeId: "hot-pot",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 7,
        name: "Loaded Vegetable Ramen with Fried Wontons 🍜🥟🥦",
        recipeId: "loaded-vegetable-ramen-fried-wontons",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 8,
        "name": "Kung Pao Vegetables with Peanuts 🌶🥦🥜",
        "recipeId": "kung-pao-veggies-peanuts",
        "story": "This vibrant, saucy stir-fry brings together bold heat, a touch of sweetness, crunchy peanuts, and tender vegetables—all served over nutty, oven-baked brown rice 🍚. It’s a better-than-takeout favorite, made your way: healthier, plant-powered, and full of heart.",
        "prep": [
            "Preheat oven to 375°F (190°C) for brown rice.",
            "Chop vegetables into bite-sized pieces.",
            "Mix sauce ingredients and set aside.",
            "Gather peanuts, dried chilies, and green onions."
        ],
        "ingredients": [
            "1 tbsp cornstarch (8g) + 1/4 cup water (60ml) for slurry",
            "1/4 cup low-sodium soy sauce or tamari (60ml)",
            "2 tbsp maple syrup or brown sugar (30ml or ~28g)",
            "1 tbsp rice vinegar (15ml)",
            "1 tbsp hoisin sauce (optional, 15ml)",
            "1 tsp sesame oil (optional, 5ml)",
            "1–2 tsp sriracha or chili paste (5–10ml)",
            "1/4 tsp red pepper flakes",
            "1 tbsp avocado or olive oil (15ml)",
            "1 red bell pepper, chopped",
            "1 green bell pepper, chopped",
            "1 small red onion, sliced",
            "2 carrots, thinly sliced",
            "1 cup mushrooms (cremini or shiitake, ~75g), quartered",
            "2 cups broccoli florets (~150g)",
            "1 stalk celery, thinly sliced",
            "1 cup shredded cabbage (green or purple, ~75g)",
            "1 tbsp fresh ginger, minced (6g)",
            "2 cloves garlic, minced",
            "3–5 dried red chilies, whole or torn",
            "1/2 cup unsalted peanuts (~70g)",
            "2 green onions, sliced for garnish"
        ],
        "instructions": [
            "Heat oil in a large skillet or wok over medium-high heat.",
            "Add dried chilies, garlic, and ginger. Stir for 30 seconds until fragrant.",
            "Add onion, bell peppers, carrots, and celery. Cook for 3–4 minutes.",
            "Add mushrooms, broccoli, and cabbage. Stir-fry for another 3–4 minutes.",
            "Give the sauce another stir and pour it in.",
            "Lower heat to medium-low. Cook 2–3 minutes until the sauce thickens.",
            "Stir in peanuts and green onions. Turn off heat.",
            "Serve hot over brown rice or noodles. Optional: garnish with lime wedges or sesame seeds."
        ],
        "subRecipes": [
            {
                "name": "Oven-Baked Brown Rice 🍚",
                "prep": [
                    "Preheat oven to 375°F (190°C).",
                    "Use a covered casserole dish (glass or ceramic)."
                ],
                "ingredients": [
                    "1 1/2 cups brown rice (285g)",
                    "2 1/2 cups water or vegetable broth (600ml)",
                    "1 tbsp olive oil (15ml)",
                    "1/2 tsp salt (optional)"
                ],
                "instructions": [
                    "Combine all ingredients in a covered casserole dish.",
                    "Cover tightly with lid or foil.",
                    "Bake for 1 hour, then fluff with a fork.",
                    "Keep covered until ready to serve."
                ]
            }
        ],
        "shortcuts": [
            "Use frozen stir-fry vegetables to save chopping time.",
            "Swap in pre-cooked brown rice or whole grain noodles if short on time.",
            "Use store-bought stir-fry sauce in a pinch—just check sodium and sugar levels!"
        ],
        "plantBased": [
            "This recipe is fully plant-based as written.",
            "Tofu or tempeh can be added for extra protein 🌱.",
            "Use tamari instead of soy sauce for gluten-free."
        ],
        "notes": [
            "For extra spice, break open the dried chilies or add more sriracha.",
            "Leftovers keep well and taste even better the next day.",
            "Try adding cubed tofu or edamame for protein-packed versions.",
            "For crunch, top with toasted sesame seeds or extra peanuts at the end."
        ],
        "brands": [
            "Soy Sauce: San-J® Tamari (low sodium)",
            "Pea Milk (if using in rice): Ripple®",
            "Olive Oil: Bryan Johnson’s Blueprint® Olive Oil"
        ],
        "tools": [
            "Large Skillet or Wok: All-Clad® or HexClad®",
            "Covered Casserole Dish: Pyrex® or CorningWare®",
            "Chef’s Knife: Victorinox® or Wüsthof®",
            "Cutting Board: OXO® Good Grips"
        ],
        "products": [
            "Silicone Spatula Set",
            "Glass Meal Prep Containers",
            "Reusable Chopsticks",
            "Serving Bowls for Stir-Fry"
        ]
    },
    {
        number: 9,
        name: "Peanut Sauce Stir Fry 🥜🍜🥕",
        recipeId: "peanut-sauce-stir-fry",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 10,
        name: "Pad Pak 🥦🇹🇭🍽️",
        recipeId: "pad-pak",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 11,
        name: "Chickpea Masala 🌶️🧄🫘",
        recipeId: "chickpea-masala",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 12,
        name: "Chinese Fried Rice and Eggrolls 🍚🥟🇨🇳",
        recipeId: "chinese-fried-rice-eggrolls",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    }
];

export default fridayDinners;